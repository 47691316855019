import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";

export default function InvestFilters() {
  return (
    <ToggleBtnGroup
      items={[
        {
          label: "Investment Pools",
          value: "InvestPools",
        },
      ]}
      selectedValue={"InvestPools"}
    />
  );
}
