import { yupResolver } from "@hookform/resolvers/yup";
import { PercentOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { investApi } from "../../../http";
import { InvestPool } from "../../../types";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { useInvestPoolForm } from "../providers/InvestPoolFormProvider";

interface FormProps {
  id?: string;
  tokenAddress: string;
  depositAddress: string;
  tenureInDays: number;
  openAt: number;
  closeAt: number;
  interestRatePercentage: number;
}

const validationSchema = Yup.object().shape({
  tokenAddress: Yup.string().required(),
  depositAddress: Yup.string()
    .required()
    .test("valid", function (value) {
      return isValidBlockchainAddress(value);
    }),
  tenureInDays: Yup.number().positive().required(),
  interestRatePercentage: Yup.number().required().positive(),
  openAt: Yup.number().required().positive(),
  closeAt: Yup.number()
    .required()
    .positive()
    .test("afterOpenAt", function (value) {
      const { openAt } = this.parent;
      return value > openAt;
    }),
});

const TENURES = [
  { value: 30 },
  { value: 60 },
  { value: 90 },
  { value: 120 },
  { value: 365 },
];

export default function InvestPoolFormInfo({ children }) {
  const { value, next, setNexting } = useInvestPoolForm();
  const { nonRwaAssetTokens } = useSelector(selectBlockchainConfigs);
  const investTokens = useMemo(
    () => nonRwaAssetTokens.filter((t) => t.invest == "Enabled"),
    [nonRwaAssetTokens]
  );
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      tenureInDays: TENURES[0].value,
      openAt: moment().valueOf(),
      closeAt: moment().valueOf(),
    },
  };
  const {
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions as any);

  const onSubmit = async (value: FormProps) => {
    try {
      setNexting(true);
      const investPool = await investApi.saveInvestPool(value);
      next({ investPool });
    } finally {
      setNexting(false);
    }
  };

  const fromDto = (investPool: InvestPool): FormProps => {
    return {
      id: investPool.id,
      tokenAddress: investPool.investToken.address,
      depositAddress: investPool.depositAddress,
      openAt: investPool.openAt,
      closeAt: investPool.closeAt,
      tenureInDays: investPool.tenureInDays,
      interestRatePercentage: investPool.interestRatePercentage,
    };
  };

  useEffect(() => {
    value?.investPool && reset(fromDto(value.investPool));
  }, [value?.investPool]);

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Invest Token</InputLabel>
          <Select
            label="Token"
            defaultValue={investTokens.length == 1 && investTokens[0].address}
            {...register("tokenAddress")}
            error={!!errors?.tokenAddress}
          >
            {investTokens.map((t) => (
              <MenuItem key={t.symbol} value={t.address}>
                {t.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          className="w-full"
          {...register("depositAddress")}
          label="Deposit Address"
          variant="filled"
          size="small"
          error={!!errors?.depositAddress}
        />

        <div className="mt-4 flex gap-4">
          <InputLabel className="basis-[100px]">Duration</InputLabel>
          <Slider
            {...register("tenureInDays")}
            step={null}
            marks={TENURES}
            min={TENURES[0].value}
            max={TENURES[TENURES.length - 1].value}
            valueLabelDisplay="on"
            valueLabelFormat={(n) => `${n} Days`}
          />
        </div>

        <TextField
          className="w-full"
          {...register("interestRatePercentage")}
          label="Interest Rate"
          inputMode="numeric"
          variant="filled"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PercentOutlined />
              </InputAdornment>
            ),
          }}
          error={!!errors?.interestRatePercentage}
        />

        <DateTimePicker
          label="Investment Open At"
          renderInput={(params) => <TextField {...params} />}
          value={watch("openAt")}
          onChange={(e) => setValue("openAt", moment(e).valueOf())}
          InputProps={{
            error: !!errors?.openAt,
          }}
        />

        <DateTimePicker
          label="Investment Close At"
          renderInput={(params) => <TextField {...params} />}
          value={watch("closeAt")}
          onChange={(e) => setValue("closeAt", moment(e).valueOf())}
          InputProps={{
            error: !!errors?.closeAt,
          }}
        />
      </div>
      {children}
    </form>
  );
}
