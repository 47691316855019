import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { CloseModal } from "../../../providers/ModalProvider";
import { InvestPool } from "../../../types";

export interface InvestPoolFormProps {
  investPool?: InvestPool;
}

interface InvestPoolFormContextProps {
  value: InvestPoolFormProps;
  setValue: Dispatch<SetStateAction<InvestPoolFormProps>>;
  step: number;
  next: (update: Partial<InvestPoolFormProps>) => void;
  back: () => void;
  close: CloseModal;
  nexting: boolean;
  setNexting: Dispatch<SetStateAction<boolean>>;
}

const InvestPoolFormContext = createContext<InvestPoolFormContextProps>(null);

interface IProps {
  close: CloseModal<InvestPool>;
  opts?: { investPool?: InvestPool };
}

export default function InvestPoolFormProvider({
  close,
  children,
  opts,
}: PropsWithChildren<IProps>) {
  const [value, setValue] = useState<InvestPoolFormProps>({
    investPool: opts?.investPool,
  });
  const [step, setStep] = useState(0);
  const [nexting, setNexting] = useState(false);

  const next = useCallback((update: Partial<InvestPoolFormProps>) => {
    setValue((value) => ({
      ...value,
      ...update,
    }));
    setStep((step) => +step + 1);
  }, []);

  const back = useCallback(() => {
    setStep((step) => Math.max(0, +step - 1));
  }, []);

  return (
    <InvestPoolFormContext.Provider
      value={{
        value,
        setValue,
        step: +step,
        next,
        back,
        close,
        nexting,
        setNexting,
      }}
    >
      {children}
    </InvestPoolFormContext.Provider>
  );
}

export const useInvestPoolForm = () => {
  return useContext(InvestPoolFormContext);
};
