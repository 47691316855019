import { createSlice } from "@reduxjs/toolkit";
import ls from "../../common/ls";
import { Admin } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getMeAction, signInAction } from "./auth.effects";
import { updateLastSeenNotificationAction } from "../notifications/notifications.effects";


export const AUTH_STATE_NAME = 'auth';

export interface AuthState {
    me: Admin | null
}

export const authState = createSlice({
    name: AUTH_STATE_NAME,
    initialState: {
        me: null,
        [getMeAction.pending.type]: true
    } as AuthState,
    reducers: {
        cancelGetMe(state) {
            state[getMeAction.pending.type] = false;
        },
        logout(state) {
            state.me = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInAction.fulfilled, (state, action) => {
                state.me = action.payload.user;
                ls.token = action.payload.accessToken;
            })
            .addCase(getMeAction.fulfilled, (state, action) => {
                state.me = action.payload;
            })
            .addCase(updateLastSeenNotificationAction.fulfilled, (state, action) => {
                state.me.lastSeenNotificationId = action.meta.arg.id;
            })
        addCommonReducers(builder, AUTH_STATE_NAME);
    }
});

export const authActions = authState.actions;
export const authReducer = authState.reducer;

