import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import { appActions } from "../../modules/app/app.state";
import { AppPage } from "../../types/app-page.type";
import * as animationData from "./under-construction-lottie.json";

const defaultOptions = {
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  }
};

export default function UnderConstruction() {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(appActions.changeRefresh({ page: AppPage.UnderConstruction }));
  });

  return (
    <div className="w-full h-full flex justify-center items-center">
      <Lottie options={defaultOptions} height={390} width={500} isClickToPauseDisabled />
    </div>
  );
}
