import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getInvestPoolsAction } from "./invest.effects";

export const selectInvestState = (state: RootState) => state.invest;

export const selectInvestPools = createSelector(
    selectInvestState,
    state => state.investPools || []
)

export const selectIsGetInvestPoolsPending = createSelector(
    selectInvestState,
    state => state[getInvestPoolsAction.pending.type]
)

export const selectInvestPoolsPagination = createSelector(
    selectInvestState,
    state => state.pagination
)