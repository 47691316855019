import { ContentCopy, CopyAll } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";

interface IProps {
  text: string;
  defaultTooltip?: string;
  className?: string;
}

export default function CopyToClipboard({
  text,
  defaultTooltip = "Copy",
  className,
}: IProps) {
  const [copiedTextObj, setCopiedTextObj] = useState<object>();
  const [copiedText, copy] = useCopyToClipboard();
  const [tooltip, setTooltip] = useState(defaultTooltip);
  const disableResetTooltip = useRef(false);

  const handleClick = () => {
    setCopiedTextObj({});
    copy(text);
  };

  const resetTooltip = () => {
    if (!disableResetTooltip.current && tooltip != defaultTooltip) {
      setTooltip(defaultTooltip);
    }
  };

  useEffect(() => {
    if (copiedTextObj) {
      disableResetTooltip.current = true;
      setTooltip("Copied!");
      setTimeout(() => (disableResetTooltip.current = false), 1000);
    }
  }, [copiedTextObj]);

  return (
    <Tooltip title={tooltip} onMouseEnter={resetTooltip}>
      <IconButton
        className={classNames("px-2 py-0 opacity-60", className)}
        size="small"
        disableRipple
        onClick={handleClick}
      >
        <ContentCopy className="w-4 h-4" />
      </IconButton>
    </Tooltip>
  );
}
