import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";

export default function CommodityTokensFilters() {
  return (
    <ToggleBtnGroup
      items={[
        {
          label: "Commodity Tokens",
          value: "CommodityTokens",
        },
      ]}
      selectedValue={"CommodityTokens"}
    />
  );
}
