import { useNavigate, useParams } from "react-router-dom";
import useBottomSheet from "../../../hooks/use-bottom-sheet";
import InvestFilters from "./InvestFilters";
import InvestPoolPage from "./InvestPoolPage";
import InvestPoolsTable from "./InvestPoolsTable";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../auth/auth.selectors";
import { useEffect } from "react";
import "./Invest.scss";

export default function Invest() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const setBottomSheet = useBottomSheet();
  const params = useParams();
  const navigate = useNavigate();

  const showInvestPoolPage = (id) => {
    setBottomSheet({
      component: <InvestPoolPage id={id} onClose={handleInvestPoolPageClose} />,
      onClose: handleInvestPoolPageClose,
    });
  };

  const handleInvestPoolPageClose = () => {
    setBottomSheet(null);
    navigate(-1);
  };

  useEffect(() => {
    const { id } = params;
    id && isAuthenticated ? showInvestPoolPage(id) : setBottomSheet(null);
  }, [params?.id, isAuthenticated]);

  return (
    <div className="w-constraint">
      <InvestFilters />
      <InvestPoolsTable />
    </div>
  );
}
