import { createSlice } from "@reduxjs/toolkit";
import { InvestPool, InvestPoolMeta, Pagination } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getInvestPoolsAction } from "./invest.effects";
import { DEFAULTS } from "../../common/constants";

export const INVEST_STATE_NAME = "invest";

export interface InvestState {
    investPools: InvestPoolMeta[];
    pagination: Pagination;
}

export const investState = createSlice({
    name: INVEST_STATE_NAME,
    initialState: {
        investPools: [],
        pagination: {
            page: 0,
            pageSize: DEFAULTS.PageSize,
            total: 0,
        },
        [getInvestPoolsAction.pending.type]: true,
    } as InvestState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInvestPoolsAction.fulfilled, (state, action) => {
            const { paginatedInvestPools, page, pageSize } = action.payload;
            state.investPools = paginatedInvestPools.data;
            state.pagination.total = paginatedInvestPools.total;
            state.pagination.page = page;
            state.pagination.pageSize = pageSize;
        });
        addCommonReducers(builder, INVEST_STATE_NAME);
    },
});

export const investActions = investState.actions;
export const investReducer = investState.reducer;
