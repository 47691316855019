import {
  AccountBalanceOutlined,
  AutoAwesomeMotionOutlined,
  AutoModeOutlined,
  BusinessOutlined,
  CategoryOutlined,
  CurrencyBitcoinOutlined,
  DashboardOutlined,
  DiscountOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  LanguageOutlined,
  LinkOutlined,
  PaidOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

export interface MenuItem {
  label: string;
  link: string;
  renderIcon: () => any;
}

export const Menu: MenuItem[][] = [
  [
    {
      label: "Dashboard",
      link: "/",
      renderIcon: () => <DashboardOutlined />,
    },
    {
      label: "Companies",
      link: "/companies",
      renderIcon: () => <BusinessOutlined />,
    },
    {
      label: "Payments",
      link: "/payments",
      renderIcon: () => <PaidOutlined />,
    },
    {
      label: "Offers",
      link: "/offers",
      renderIcon: () => <FileUploadOutlined />,
    },
    {
      label: "Requests",
      link: "/requests",
      renderIcon: () => <FileDownloadOutlined />,
    },
  ],
  [
    {
      label: "Plans",
      link: "/plans",
      renderIcon: () => <AutoAwesomeMotionOutlined />,
    },
    // {
    //   label: "Discounts",
    //   link: "/discounts",
    //   renderIcon: () => <DiscountOutlined />,
    // },
    // {
    //   label: "Categories",
    //   link: "/categories",
    //   renderIcon: () => <CategoryOutlined />,
    // },
    // {
    //   label: "Countries",
    //   link: "/countries",
    //   renderIcon: () => <LanguageOutlined />,
    // },
  ],
  [
    {
      label: "Smart Deals",
      link: "/smart-deals",
      renderIcon: () => <LinkOutlined />,
    },
    {
      label: "Commodity Tokens",
      link: "/commodity-tokens",
      renderIcon: () => <CurrencyBitcoinOutlined />,
    },
    {
      label: "Investments",
      link: "/investments",
      renderIcon: () => <AccountBalanceOutlined />,
    },
  ],
  [
    {
      label: "Schedules",
      link: "/schedules",
      renderIcon: () => <AutoModeOutlined />,
    },
  ],
];

export const SettingsMenu = {
  label: "Settings",
  link: "/settings",
  renderIcon: () => <SettingsOutlined />,
};
