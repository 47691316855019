import CommodityTokensFilters from "./CommodityTokensFilters";
import CommodityTokensTable from "./CommodityTokensTable";

export default function CommodityTokens() {
  return (
    <div className="w-constraint">
      <CommodityTokensFilters />
      <CommodityTokensTable />
    </div>
  );
}
