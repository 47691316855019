import {
  Add,
  DeleteForeverOutlined,
  MoreVert,
  PlayArrowOutlined,
} from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridFooter } from "@mui/x-data-grid";
import classNames from "classnames";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InvestPoolStateChip from "../../../components/InvestPoolStateChip/InvestPoolStateChip";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import useDeleteConfirm from "../../../hooks/use-delete-confirm";
import { investApi } from "../../../http";
import { useModal } from "../../../providers/ModalProvider";
import {
  AppPage,
  AppRefreshStatus,
  InvestPoolMeta,
  InvestPoolStatus,
  Pagination,
} from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import { getInvestPoolsAction } from "../invest.effects";
import {
  selectInvestPools,
  selectInvestPoolsPagination,
  selectIsGetInvestPoolsPending,
} from "../invest.selectors";
import InvestPoolFormProvider from "../providers/InvestPoolFormProvider";
import InvestPoolForm from "./InvestPoolForm";

export default function InvestPoolsTable() {
  const investPools = useSelector(selectInvestPools);
  const pagination = useSelector(selectInvestPoolsPagination);
  const isGetInvestPoolsPending = useSelector(selectIsGetInvestPoolsPending);
  const [rows, setRows] = useState([]);
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const [selectedMenuInvestPool, setSelectedMenuInvestPool] =
    useState<InvestPoolMeta | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();
  const openModal = useModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirmDelete = useDeleteConfirm();

  const getInvestPools = (pg) => {
    dispatch(getInvestPoolsAction(pg));
  };

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    getInvestPools(pg);
  };

  const handleRowClick = (investPool: InvestPoolMeta) => {
    navigate(investPool.poolId);
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    pool: InvestPoolMeta
  ) => {
    e.stopPropagation();
    setSelectedMenuInvestPool(pool);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleAddNewPool = async () => {
    if (
      await openModal(
        (props) => (
          <InvestPoolFormProvider {...props}>
            <InvestPoolForm />
          </InvestPoolFormProvider>
        ),
        {
          closeOnClickOutside: false,
          hideCloseButton: true,
        }
      )
    ) {
      getInvestPools({});
      enqueueSnackbar(`Invest pool added successfully.`, {
        variant: "info",
      });
    }
  };

  const handlePublish = async () => {
    closeMenu();
    await investApi.publishInvestPool(selectedMenuInvestPool.poolId);
    enqueueSnackbar("Invest Pool published to blockchain successfully.", {
      variant: "info",
    });
    getInvestPools({});
  };

  const handleDelete = async () => {
    closeMenu();
    if (
      await confirmDelete({
        title: "Invest Pool",
        onConfirm: () =>
          investApi.deleteInvestPool(selectedMenuInvestPool.poolId),
      })
    ) {
      enqueueSnackbar(`Invest Pool deleted successfully.`, { variant: "info" });
      getInvestPools({});
    }
  };

  const cols: GridColDef[] = useMemo(
    () =>
      [
        {
          field: "rowNo",
          headerName: "",
          width: 50,
          sortable: false,
        },
        {
          field: "totalInvest",
          headerName: "Total Invest",
          renderCell: ({ row }: { row: InvestPoolMeta }) =>
            `${row.totalInvest} ${row.pool.investToken.symbol}`,
          flex: 1,
          sortable: false,
        },
        {
          field: "interestRatePercentage",
          headerName: "Rate",
          renderCell: ({ row }: { row: InvestPoolMeta }) =>
            `${row.pool.interestRatePercentage}%`,
          width: 70,
          sortable: false,
        },
        {
          field: "state",
          headerName: "Pool State",
          renderCell: ({ row }: { row: InvestPoolMeta }) => (
            <InvestPoolStateChip pool={row.pool} />
          ),
          headerAlign: "center",
          align: "center",
          flex: 1,
          sortable: false,
        },
        {
          field: "openAt",
          headerName: "Open At",
          renderCell: ({ row }: { row: InvestPoolMeta }) => (
            <div className="flex flex-col">
              <span>
                {moment(row.pool.openAt).format("YYYY-MM-DD hh:mm A")}
              </span>
              <span>
                {moment(row.pool.closeAt).format("YYYY-MM-DD hh:mm A")}
              </span>
            </div>
          ),
          flex: 1,
          sortable: false,
        },
        {
          field: "settleAt",
          headerName: "Settle At",
          renderCell: ({ row }: { row: InvestPoolMeta }) => (
            <div className="flex flex-col">
              <span>Duration: {row.pool.tenureInDays} days</span>
              <span>Settle: {moment(row.pool.settleAt).fromNow()}</span>
            </div>
          ),

          flex: 1,
          sortable: false,
        },
        {
          field: "createdAt",
          headerName: "Created At",
          renderCell: ({ row }: { row: InvestPoolMeta }) =>
            moment(row.pool.createdAt).format("YYYY-MM-DD"),
          width: 100,
          sortable: false,
        },
        {
          field: "",
          renderCell: (params) => (
            <IconButton onClick={(e) => showMenu(e, params.row)}>
              <MoreVert />
            </IconButton>
          ),
          width: 60,
          sortable: false,
        },
      ] as GridColDef[],
    []
  );

  useEffect(() => {
    setRows(
      (investPools?.map((investPool, index) => ({
        ...investPool,
        rowNo: pagination.page * pagination.pageSize + index + 1,
      })) as any) || []
    );
  }, [investPools]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.InvestPools
    ) {
      getInvestPools({});
    }
  }, [refreshStatus]);

  return (
    <>
      <Button
        className="mt-3 float-right rounded-3xl"
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleAddNewPool}
      >
        <Add /> Add New Pool
      </Button>

      <div className="w-full mt-4">
        <DataGrid
          columns={cols}
          rows={rows}
          getRowId={(row) => row.poolId}
          disableColumnMenu
          disableSelectionOnClick
          style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
          loading={isGetInvestPoolsPending}
          pagination
          paginationMode="server"
          autoPageSize
          rowCount={pagination.total}
          page={pagination.page}
          onPageChange={(page) => handlePagination({ page })}
          onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
          components={{
            NoRowsOverlay: () => (
              <NoRowsOverlay emptyMessage="No investment pools" />
            ),
            Footer: (props) => (
              <div className="relative">
                <GridFooter {...props} />
                <div className="absolute left-0 top-0 bottom-0 px-6 flex gap-4">
                  <div className="flex items-center gap-2">
                    <small>Draft</small>
                    <div className="w-4 h-4 rounded bg-yellow-300"></div>
                  </div>
                </div>
              </div>
            ),
          }}
          getRowClassName={({ row }: { row: InvestPoolMeta }) =>
            classNames(
              "cursor-pointer",
              row.pool.status == InvestPoolStatus.Draft && "bg-yellow-200"
            )
          }
          onRowClick={({ row }) => handleRowClick(row)}
        />
        <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
          <div className="flex flex-col px-1">
            <Button
              className="justify-start"
              variant="text"
              color="info"
              startIcon={<PlayArrowOutlined />}
              disabled={
                selectedMenuInvestPool?.pool.status != InvestPoolStatus.Draft
              }
              onClick={handlePublish}
            >
              Publish
            </Button>
            <Divider />
            <Button
              color="error"
              variant="text"
              startIcon={<DeleteForeverOutlined />}
              disabled={
                selectedMenuInvestPool?.pool.status != InvestPoolStatus.Draft
              }
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </MoreMenu>
      </div>
    </>
  );
}
