import { createAsyncThunk } from "@reduxjs/toolkit";
import { appActions } from "../app/app.state";
import { AppPage, AppRefreshStatus } from "../../types";
import { commodityTokenApi } from "../../http/commodity-tokens.api";

export const getCommodityTokensAction = createAsyncThunk(
    'commodityTokens/getCommodityTokens',
    async (_, thunk) => {
        try {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.CommodityTokens
            }));

            return commodityTokenApi.getCommodityTokens();

        } finally {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.CommodityTokens
            }));
        }
    }
)