import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApi, meApi } from "../../http";
import { Notification } from "../../types";

export const signInAction = createAsyncThunk(
    'auth/login',
    ({ username, password }: { username: string, password: string }): Promise<{ accessToken, user }> => {
        return authApi.signin(username, password);
    }
)

export const getMeAction = createAsyncThunk(
    'auth/me',
    (): Promise<any> => {
        return authApi.getMe();
    }
)