import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store/root.reducer";
import { getMeAction, signInAction } from "./auth.effects";

export const selectAuthState = (state: RootState) => state.auth;

export const selectMe = createSelector(selectAuthState, (state) => state.me);

export const selectIsAuthenticated = createSelector(selectMe, (me) => !!me);

export const selectIsLoginPending = createSelector(
    selectAuthState,
    (state) => state[signInAction.pending.type]
);

export const selectIsGetMePending = createSelector(
    selectAuthState,
    (state) => state[getMeAction.pending.type]
);