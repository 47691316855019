import classNames from "classnames";
import { SmartDealState } from "../../modules/blockchain/types";
import { getSmartDealStatusIndicatorColor } from "../../utils/coimex-utils";

interface IProps {
  status: SmartDealState;
}

export default function SmartDealStatusIndicator({ status }: IProps) {
  const color = getSmartDealStatusIndicatorColor(status);
  return (
    <div className="flex items-center gap-1 text-xs">
      <span className="mr-1">Status</span>
      <span
        className={classNames("rounded-full", "w-2", "h-2", `bg-${color}`)}
      ></span>
      <span className={classNames(`text-${color}`)}>{status}</span>
    </div>
  );
}
