import { LoadingButton } from "@mui/lab";
import { CircularProgress, Paper } from "@mui/material";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import ContractAvailableBalance from "../../../components/ContractAvailableBalance/ContractAvailableBalance";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import InfoPopup from "../../../components/InfoPopup/InfoPopup";
import SmartDealPaymentStatusIndicator from "../../../components/SmartDealPaymentStatusIndicator/SmartDealPaymentStatusIndicator";
import { blockchainApi } from "../../../http/blockchain.api";
import { useModal } from "../../../providers/ModalProvider";
import {
  hasBlockChainTxEventOfType,
  isBlockchainAddressZero,
} from "../../../utils/blockchain-utils";
import { hasAllPaymentEventsReceived } from "../../../utils/coimex-utils";
import { useBlockchainTransferEvent } from "../providers/BlockchainTransferEventProvider";
import {
  BlockchainEventType,
  SmartDeal,
  SmartDealPayment,
  SmartDealPaymentBlockchainInfo,
} from "../types";
import SmartDealPaymentFlow from "./SmartDealPaymentFlow/SmartDealPaymentFlow";
import SmartDealPaymentForm from "./SmartDealPaymentForm";

interface IProps {
  smartDeal: SmartDeal;
}

export default function SmartDealPayments({ smartDeal }: IProps) {
  const [payment, setPayment] = useState<SmartDealPayment>();
  const [isGetPaymentPending, setIsGetPaymentPending] = useState(true);
  const [paymentBlockchainInfo, setPaymentBlockchainInfo] =
    useState<SmartDealPaymentBlockchainInfo>();
  const [
    isGetPaymentBlockchainInfoPending,
    setIsGetPaymentBlockchainInfoPending,
  ] = useState(false);
  const { onPaymentTransfer, onFeeTransfer } = useBlockchainTransferEvent();
  const [invalidatePaymentObj, setInvalidatePaymentObj] = useState<object>();
  const debouncedInvalidatePayment = useDebounce(invalidatePaymentObj, 2000);
  const openModal = useModal();
  const isDepositAddressPending = useMemo(
    () =>
      isGetPaymentBlockchainInfoPending ||
      !paymentBlockchainInfo ||
      isBlockchainAddressZero(paymentBlockchainInfo.depositAddress),
    [isGetPaymentBlockchainInfoPending, paymentBlockchainInfo]
  );
  const hasAllTransactionsReceived = useMemo(
    () => hasAllPaymentEventsReceived(payment?.paymentActions),
    [payment]
  );

  const getPayment = async () => {
    try {
      setIsGetPaymentPending(true);
      setPayment(await blockchainApi.getSmartDealPayment(smartDeal.id));
    } finally {
      setIsGetPaymentPending(false);
    }
  };

  const getPaymentBlockchainInfo = async () => {
    try {
      setIsGetPaymentBlockchainInfoPending(true);
      setPaymentBlockchainInfo(
        await blockchainApi.getSmartDealPaymentBlockchainInfo(smartDeal.id)
      );
    } finally {
      setIsGetPaymentBlockchainInfoPending(false);
    }
  };

  const openSmartDealPaymentForm = async () => {
    setPayment(
      await openModal((props) => (
        <SmartDealPaymentForm smartDeal={smartDeal} {...props} />
      ))
    );
  };

  const handleUpdate = useCallback((update: Partial<SmartDealPayment>) => {
    setPayment((payment) => ({ ...payment, ...update }));
  }, []);

  const handleInvalidate = useCallback(() => {
    getPayment();
    getPaymentBlockchainInfo();
  }, []);

  const handleBLockchainTransferNotification = (event: {
    from: string;
    to: string;
  }) => {
    if (
      event.from == paymentBlockchainInfo?.depositAddress ||
      event.to == paymentBlockchainInfo?.depositAddress
    ) {
      setInvalidatePaymentObj({});
    }
  };

  useEffect(() => {
    if (debouncedInvalidatePayment) {
      handleInvalidate();
    }
  }, [debouncedInvalidatePayment]);

  useEffect(() => {
    if (paymentBlockchainInfo?.depositAddress) {
      onPaymentTransfer?.on(
        paymentBlockchainInfo.depositAddress,
        handleBLockchainTransferNotification
      );

      onFeeTransfer?.on(
        paymentBlockchainInfo.depositAddress,
        handleBLockchainTransferNotification
      );
    }

    return () => {
      onPaymentTransfer?.off(
        paymentBlockchainInfo?.depositAddress,
        handleBLockchainTransferNotification
      );

      onFeeTransfer?.off(
        paymentBlockchainInfo?.depositAddress,
        handleBLockchainTransferNotification
      );
    };
  }, [onPaymentTransfer, paymentBlockchainInfo?.depositAddress]);

  useEffect(() => {
    payment?.dealHash && getPaymentBlockchainInfo();
  }, [payment?.dealHash]);

  useEffect(() => {
    smartDeal?.id && getPayment();
  }, [smartDeal?.id]);

  return (
    <div className="relative max-w-4xl mx-auto flex flex-col">
      {!isGetPaymentPending ? (
        payment ? (
          <div
            className="flex-1 overflow-auto"
            style={{
              maxHeight: "calc(100vh - 190px)",
            }}
          >
            <Paper
              className="flex flex-col gap-y-6 p-12 card-border"
              elevation={0}
            >
              {/* payment information */}
              <section className="smart-deal-info-section">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="text-lg font-medium">Payment Information</h2>
                  {!isDepositAddressPending && (
                    <SmartDealPaymentStatusIndicator
                      isClosed={paymentBlockchainInfo.isClose}
                    />
                  )}
                </div>

                <div className="p-3 flex flex-col gap-6">
                  <div className="info-section-item">
                    <span>Importer Refund Address</span>
                    <span className="info-section-item__value">
                      <a
                        className="link"
                        href={payment.importerWalletAddressUrl}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {payment.importerWalletAddress}
                      </a>
                      <CopyToClipboard
                        text={payment.importerWalletAddress}
                        defaultTooltip="Copy Address"
                      />
                    </span>
                  </div>

                  <div className="info-section-item">
                    <span>Exporter Funds Payout Address</span>
                    <span className="info-section-item__value">
                      <a
                        className="link"
                        href={payment.exporterWalletAddressUrl}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {payment.exporterWalletAddress}
                      </a>
                      <CopyToClipboard
                        text={payment.exporterWalletAddress}
                        defaultTooltip="Copy Address"
                      />
                    </span>
                  </div>

                  {payment.createPaymentTx ? (
                    <>
                      {!isGetPaymentBlockchainInfoPending ? (
                        paymentBlockchainInfo && (
                          <>
                            <div className="info-section-item">
                              <span>Fee Collecting Address</span>
                              <span className="info-section-item__value">
                                <a
                                  className="link"
                                  href={
                                    paymentBlockchainInfo.paymentContractAddressUrl
                                  }
                                  target="_blank"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {paymentBlockchainInfo.paymentContractAddress}
                                </a>
                                <CopyToClipboard
                                  text={paymentBlockchainInfo.depositAddress}
                                  defaultTooltip="Copy Address"
                                />
                              </span>
                            </div>

                            {!isBlockchainAddressZero(
                              paymentBlockchainInfo.depositAddress
                            ) && (
                              <>
                                <div className="info-section-item">
                                  <span>
                                    Deposit Address
                                    <InfoPopup text="Buyer should send payments and fees directly to this address" />
                                  </span>
                                  <span className="info-section-item__value">
                                    <a
                                      className="link"
                                      href={
                                        paymentBlockchainInfo.depositAddressUrl
                                      }
                                      target="_blank"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      {paymentBlockchainInfo.depositAddress}
                                    </a>
                                    <CopyToClipboard
                                      text={
                                        paymentBlockchainInfo.depositAddress
                                      }
                                      defaultTooltip="Copy Address"
                                    />
                                  </span>
                                </div>

                                <div className="info-section-item">
                                  <span>Available Balance</span>
                                  <div className="info-section-item__value flex items-center gap-2">
                                    <ContractAvailableBalance
                                      className={classNames({
                                        "blur-xs": !hasAllTransactionsReceived,
                                      })}
                                      {...(paymentBlockchainInfo as any)}
                                    />
                                    {!hasAllTransactionsReceived && (
                                      <span className="italic flex items-center gap-2 opacity-80">
                                        Waiting for transaction
                                        <CircularProgress size={12} />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <div className="mt-4 flex justify-center">
                          <CircularProgress color="primary" size="32px" />
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </section>

              {!isGetPaymentBlockchainInfoPending && paymentBlockchainInfo && (
                <section className="smart-deal-info-section">
                  <div className="flex justify-between items-center pr-2">
                    <h2 className="text-lg font-medium">Payment Flow</h2>
                  </div>

                  <div className="flex justify-center">
                    <SmartDealPaymentFlow
                      proformaInvoice={smartDeal.proformaInvoice}
                      payment={payment}
                      paymentBlockchainInfo={paymentBlockchainInfo}
                      disabled={
                        !hasAllTransactionsReceived ||
                        !hasBlockChainTxEventOfType(
                          payment.createPaymentTx,
                          BlockchainEventType.PaymentCreated
                        )
                      }
                      onUpdate={handleUpdate}
                      onInvalidate={handleInvalidate}
                    />
                  </div>
                </section>
              )}
            </Paper>
          </div>
        ) : (
          <div className="mt-10 w-full max-w-lg self-center flex flex-col gap-0.5 items-center text-text-1 italic">
            <p>You haven't yet created any payment</p>
            <p className="text-sm">Click button bellow to start</p>
            <LoadingButton
              className="w-full mt-4 h-12"
              variant="contained"
              color="secondary"
              disabled={
                !hasBlockChainTxEventOfType(
                  smartDeal.createDealTx,
                  BlockchainEventType.DealCreated
                )
              }
              onClick={openSmartDealPaymentForm}
            >
              Create Payment
            </LoadingButton>
          </div>
        )
      ) : (
        <div className="absolute left-0 right-0 top-16 flex justify-center">
          <CircularProgress color="primary" size="32px" />
        </div>
      )}
    </div>
  );
}
