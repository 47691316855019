import { Business, Edit, OpenInNew, Person } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, IconButton, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockchainTxStatusIndicator from "../../../components/BlockchainTxStatusIndicator/BlockchainTxStatusIndicator";
import ContractCurrency from "../../../components/ContractCurrency/ContractCurrency";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import SmartDealStatusIndicator from "../../../components/SmartDealStatusIndicator/SmartDealStatusIndicator";
import { blockchainApi } from "../../../http/blockchain.api";
import { useModal } from "../../../providers/ModalProvider";
import { AppPage, AppRefreshStatus } from "../../../types";
import { toShortHash } from "../../../utils/blockchain-utils";
import { fromNow } from "../../../utils/dt-utils";
import { appActions } from "../../app/app.state";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { useBlockchainEventsModal } from "../providers/BlockchainEventsModalProvider";
import SmartDealFormProvider from "../providers/SmartDealFormProvider";
import { SmartDeal, SmartDealState } from "../types";
import ProformaInvoiceForm from "./ProformaInvoiceForm";
import "./SmartDeals.scss";

interface IProps {
  smartDeal: SmartDeal;
  onUpdate: (update: Partial<SmartDeal>) => void;
}

export default function SmartDealInfo({ smartDeal, onUpdate }: IProps) {
  const configs = useSelector(selectBlockchainConfigs);
  const [isPublishing, setIsPublishing] = useState(false);
  const showEvents = useBlockchainEventsModal();
  const openModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const openProformaInvoiceEditForm = async () => {
    const proformaInvoice = await openModal(
      (props) => (
        <SmartDealFormProvider
          opts={{ smartDeal, disableGetCompanies: true }}
          {...props}
        >
          <ProformaInvoiceForm />
        </SmartDealFormProvider>
      ),
      {
        hideCloseButton: false,
      }
    );
    proformaInvoice && onUpdate({ proformaInvoice });
  };

  const publishToBlockchain = async () => {
    try {
      setIsPublishing(true);
      smartDeal = await blockchainApi.publishSmartDealToBlockchain(
        smartDeal.id
      );
      onUpdate({ ...smartDeal });
      dispatch(
        appActions.changeRefresh({
          page: AppPage.SmartDeals,
          status: AppRefreshStatus.Invalidated,
        })
      );
      enqueueSnackbar(`Smart Deal published to blockchain successfully.`, {
        variant: "info",
      });
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto flex flex-col">
      <div
        className="flex-1 overflow-auto"
        style={{
          maxHeight: `calc(100vh - ${
            smartDeal.state === SmartDealState.Draft ? "240" : "190"
          }px)`,
        }}
      >
        <Paper className="flex flex-col gap-y-6 p-12 card-border" elevation={0}>
          {/* smart-deal information */}
          <section className="smart-deal-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Deal Information</h2>
              <SmartDealStatusIndicator status={smartDeal.state} />
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>RefID</span>
                <span className="info-section-item__value">
                  {smartDeal.refId}
                </span>
              </div>

              <div className="info-section-item">
                <span>Exporter Company</span>
                <span className="info-section-item__value">
                  <div className="flex items-center gap-x-2">
                    <Avatar
                      src={smartDeal.exporterCompany.avatarUrl}
                      sx={{ width: 28, height: 28 }}
                    >
                      <Business fontSize="small" />
                    </Avatar>
                    <span>{smartDeal.exporterCompany.name}</span>
                  </div>
                </span>
              </div>

              <div className="info-section-item">
                <span>Importer Company</span>
                <span className="info-section-item__value">
                  <div className="flex items-center gap-x-2">
                    <Avatar
                      src={smartDeal.importerCompany.avatarUrl}
                      sx={{ width: 28, height: 28 }}
                    >
                      <Business fontSize="small" />
                    </Avatar>
                    <span>{smartDeal.importerCompany.name}</span>
                  </div>
                </span>
              </div>

              <div className="info-section-item">
                <span>Created By</span>
                <span className="info-section-item__value">
                  <div className="flex items-center gap-x-2">
                    <Avatar sx={{ width: 28, height: 28 }}>
                      <Person fontSize="small" />
                    </Avatar>
                    <span>{smartDeal.creator.username}</span>
                  </div>
                </span>
              </div>

              <div className="info-section-item">
                <span>Created At</span>
                <span className="info-section-item__value">
                  {fromNow(smartDeal.createdAt)}
                </span>
              </div>
            </div>
          </section>

          {/* proforma invoice */}
          <section className="smart-deal-info-section">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Proforma Invoice</h2>
              {smartDeal.state === SmartDealState.Draft && (
                <IconButton
                  className="-mt-1"
                  size="small"
                  onClick={openProformaInvoiceEditForm}
                >
                  <Edit />
                </IconButton>
              )}
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Proforma Invoice Date</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.proformaInvoiceDate}
                </span>
              </div>

              <div className="info-section-item">
                <span>Exporter Company Name</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.exporterCompanyName}
                </span>
              </div>

              <div className="info-section-item">
                <span>Importer Company Name</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.importerCompanyName}
                </span>
              </div>

              <div className="info-section-item">
                <span>Fixed Fee</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.fixedFee != null ? (
                    <ContractCurrency
                      icon={configs?.feeTokenIcon}
                      amount={smartDeal.proformaInvoice?.fixedFee}
                    />
                  ) : null}
                </span>
              </div>

              <div className="info-section-item">
                <span>Commission Fee</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.commissionFee != null ? (
                    <ContractCurrency
                      icon={configs?.feeTokenIcon}
                      amount={smartDeal.proformaInvoice?.commissionFee}
                    />
                  ) : null}
                </span>
              </div>

              <div className="info-section-item">
                <span>Total Amount</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.paymentTotal != null ? (
                    <ContractCurrency
                      icon={configs?.paymentTokenIcon}
                      amount={smartDeal.proformaInvoice?.paymentTotal}
                    />
                  ) : null}
                </span>
              </div>

              <div className="info-section-item">
                <span>Terms of Payment(percentage)</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.paymentPercentage}
                  {smartDeal.proformaInvoice?.paymentPercentage != null && "%"}
                </span>
              </div>

              <div className="info-section-item">
                <span>Terms of Payment</span>
                <span className="info-section-item__value">
                  {smartDeal.proformaInvoice?.paymentTerms}
                </span>
              </div>
            </div>
          </section>

          {/* create deal tx */}
          {smartDeal.createDealTx ? (
            <section className="smart-deal-info-section">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-medium">Create Deal Tx</h2>
                <BlockchainTxStatusIndicator
                  status={smartDeal.createDealTx?.status}
                />
              </div>

              <div className="p-3 flex flex-col gap-4">
                <div className="info-section-item">
                  <span>Tx Hash</span>
                  <span className="info-section-item__value">
                    <a
                      className="link"
                      href={smartDeal.createDealTx.txUrl}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {toShortHash(smartDeal.createDealTx.txHash)}
                    </a>
                    <CopyToClipboard
                      text={smartDeal.createDealTx.txHash}
                      defaultTooltip="Copy Tx hash"
                    />
                  </span>
                </div>

                <div className="info-section-item">
                  <span>Broadcasted At</span>
                  <span className="info-section-item__value">
                    {fromNow(smartDeal.createDealTx.createdAt)}
                  </span>
                </div>

                <div className="info-section-item">
                  <span>Received Events</span>
                  <span className="info-section-item__value">
                    <OpenInNew
                      className="link"
                      onClick={() => showEvents(smartDeal.createDealTx)}
                    />
                  </span>
                </div>
              </div>
            </section>
          ) : null}
        </Paper>
      </div>

      {smartDeal.state === SmartDealState.Draft && (
        <div className="px-4 mt-4 flex justify-end">
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            loading={isPublishing}
            onClick={publishToBlockchain}
          >
            Publish to Blockchain
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
