import api from ".";
import { Admin } from "../types";

export const authApi = {
    signin: async (username: string, password: string): Promise<{ user: any, accessToken: string }> => {
        return (await api.post('/admin/signin', {
            username,
            password
        })).data;
    },
    getMe: async (): Promise<Admin> => {
        return (await api.get('/admin')).data;
    },
    forgotPassword: async (email: string): Promise<void> => {
        return (await api.post('/admin/forget-password', {
            email
        }));
    },
    resetPassword: async (email: string, code: string, newPassword: string): Promise<any> => {
        return (await api.post('/admin/reset-password', {
            email,
            code,
            newPassword
        }))
    }
}