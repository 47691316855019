import { Logout } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverProps,
} from "@mui/material";

interface IProps {
  open: boolean;
  anchorEl: PopoverProps["anchorEl"];
  onClick: () => void;
  onClose: () => void;
}

export default function ProfileMenu({
  open,
  anchorEl,
  onClick,
  onClose,
}: IProps) {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem onClick={onClick}>
        <ListItemText>Sign out</ListItemText>
        <ListItemIcon className="ml-3 -mr-4">
          <Logout fontSize="small" />
        </ListItemIcon>
      </MenuItem>
    </Menu>
  );
}
