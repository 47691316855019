import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loading from "../../../../components/Loading/Loading";
import useFocus from "../../../../hooks/use-focus";
import {
  Category,
  CompanyRoles,
  Plan,
  Region,
  SubCategory,
} from "../../../../types";
import {
  inCompanyRoles,
  toAbsoluteUrl,
  toRoles,
} from "../../../../utils/coimex-utils";
import { selectPlans } from "../../../plans/plans.selectors";
import {
  selectCategories,
  selectCountries,
  selectIsGetConfigsPending,
  selectRegions,
  selectSubCategories,
} from "../../../settings/settings.selectors";
import { useCompanyForm } from "./CompanyFormProvider";

export default function CompanyFormCategoriesAndCountries({ children }) {
  const { company, next } = useCompanyForm();
  const [plan, setPlan] = useState<Plan>();
  const isImporter = inCompanyRoles(
    CompanyRoles.Importer,
    toRoles(company.purposeOfUse)
  );
  const isExporter = inCompanyRoles(
    CompanyRoles.Exporter,
    toRoles(company.purposeOfUse)
  );
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const regions = useSelector(selectRegions);
  const countries = useSelector(selectCountries);
  const plans = useSelector(selectPlans);
  const isLoading = useSelector(selectIsGetConfigsPending);
  const validationSchema = Yup.object().shape({
    importSubCategoryIds: isImporter
      ? Yup.array()
          .min(1, "Select atleast one category.")
          .max(
            plan?.quota?.categories,
            `Select atmost ${plan?.quota?.categories} categories`
          )
      : undefined,
    importCountryIds: isImporter
      ? Yup.array().min(1, "Select atleast one country.")
      : undefined,
    exportSubCategoryIds: isExporter
      ? Yup.array()
          .min(1, "Select atleast one category.")
          .max(
            plan?.quota?.categories,
            `Select atmost ${plan?.quota?.categories} categories`
          )
      : undefined,
    exportCountryIds: isExporter
      ? Yup.array().min(1, "Select atleast one country.")
      : undefined,
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm(formOptions as any);
  const companyNameRef = useRef();
  useFocus(companyNameRef);

  const renderCategory = (
    category: Category,
    selectedIds: SubCategory["id"][]
  ) => {
    const subCategories = category.subCategories.map((s) => (
      <MenuItem key={s.id} value={s.id}>
        <Checkbox checked={!!selectedIds?.includes(s.id)} />
        <ListItemText primary={s.name} />
      </MenuItem>
    ));

    return [<ListSubheader>{category.name}</ListSubheader>, subCategories];
  };

  const renderRegion = (region: Region, selectedIds: SubCategory["id"][]) => {
    const subCountries = region.countries
      .map((country) => countries.find((c) => c.id == country.id))
      .map((c) => (
        <MenuItem key={c.id} value={c.id}>
          <Checkbox checked={!!selectedIds?.includes(c.id)} />
          <div className="flex gap-2">
            <img loading="lazy" width="20" src={c.flagUrl} alt={c.name} />
            {c.name} ({c.cca3}) +{c.dialingCode}
          </div>
        </MenuItem>
      ));

    return [<ListSubheader>{region.name}</ListSubheader>, subCountries];
  };

  const onSubmit = (value) => {
    next(value);
  };

  useEffect(() => {
    reset(company);
  }, [company]);

  useEffect(() => {
    if (company && plans.length) {
      setPlan(plans.find((p) => p.id == company.planId));
    }
  }, [company, plans]);

  return (
    <div className="flex-1 w-full">
      {isLoading ? (
        <Loading size={40} />
      ) : (
        <form
          className="flex-1 w-full flex flex-col gap-4 mt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex-1 flex flex-col gap-6 px-1">
            {isImporter && (
              <div>
                <FormControl
                  fullWidth
                  variant="filled"
                  error={!!errors?.importSubCategoryIds}
                  required
                >
                  <InputLabel>Import Categories</InputLabel>
                  <Select
                    label="Import Categories"
                    defaultValue={company.importSubCategoryIds || []}
                    value={watch("importSubCategoryIds") || []}
                    onChange={(e) =>
                      setValue("importSubCategoryIds", e.target.value)
                    }
                    renderValue={(selected) => (
                      <span className="text-sm">
                        {selected
                          .map(
                            (id) => subCategories.find((s) => s.id == id).name
                          )
                          .join(", ")}
                      </span>
                    )}
                    error={!!errors?.importSubCategoryIds}
                    multiple
                  >
                    {categories.map((c) =>
                      renderCategory(c, watch("importSubCategoryIds"))
                    )}
                  </Select>
                </FormControl>
                <FormHelperText className="text-right -mb-2">
                  Max {plan?.quota?.categories} categories
                </FormHelperText>
              </div>
            )}

            {isImporter && (
              <FormControl
                className="mb-1.5"
                fullWidth
                variant="filled"
                error={!!errors?.importCountryIds}
                required
              >
                <InputLabel>Import Countries</InputLabel>
                <Select
                  label="Import Countries"
                  defaultValue={company.importCountryIds || []}
                  value={watch("importCountryIds") || []}
                  onChange={(e) => setValue("importCountryIds", e.target.value)}
                  renderValue={(selected) => (
                    <span className="text-sm">
                      {selected
                        .map((id) => countries.find((s) => s.id == id).name)
                        .join(", ")}
                    </span>
                  )}
                  error={!!errors?.importCountryIds}
                  multiple
                >
                  {regions.map((r) =>
                    renderRegion(r, watch("importCountryIds"))
                  )}
                </Select>
              </FormControl>
            )}

            {isExporter && (
              <div>
                <FormControl
                  fullWidth
                  variant="filled"
                  error={!!errors?.exportSubCategoryIds}
                  required
                >
                  <InputLabel>Export Categories</InputLabel>
                  <Select
                    label="Export Categories"
                    defaultValue={company.exportSubCategoryIds || []}
                    value={watch("exportSubCategoryIds") || []}
                    onChange={(e) =>
                      setValue("exportSubCategoryIds", e.target.value)
                    }
                    renderValue={(selected) => (
                      <span className="text-sm">
                        {selected
                          .map(
                            (id) => subCategories.find((s) => s.id == id).name
                          )
                          .join(", ")}
                      </span>
                    )}
                    error={!!errors?.exportSubCategoryIds}
                    multiple
                  >
                    {categories.map((c) =>
                      renderCategory(c, watch("exportSubCategoryIds"))
                    )}
                  </Select>
                </FormControl>
                <FormHelperText className="text-right -mb-2">
                  Max {plan?.quota?.categories} categories
                </FormHelperText>
              </div>
            )}

            {isExporter && (
              <FormControl
                fullWidth
                variant="filled"
                error={!!errors?.exportCountryIds}
                required
              >
                <InputLabel>Export Countries</InputLabel>
                <Select
                  label="Export Countries"
                  defaultValue={company.exportCountryIds || []}
                  value={watch("exportCountryIds") || []}
                  onChange={(e) => setValue("exportCountryIds", e.target.value)}
                  renderValue={(selected) => (
                    <span className="text-sm">
                      {selected
                        .map((id) => countries.find((s) => s.id == id).name)
                        .join(", ")}
                    </span>
                  )}
                  error={!!errors?.exportCountryIds}
                  multiple
                >
                  {regions.map((r) =>
                    renderRegion(r, watch("exportCountryIds"))
                  )}
                </Select>
              </FormControl>
            )}
          </div>
          {children}
        </form>
      )}
    </div>
  );
}
