import { Chip } from "@mui/material";
import { now } from "moment";
import { InvestPool, InvestPoolState } from "../../types";
import {
  getInvestPoolStateColor,
  InvestPoolStateText,
} from "../../utils/coimex-utils";
import ChipWithProgress from "../ChipWithProgress/ChipWithProgress";

interface IProps {
  pool: InvestPool;
}

export default function InvestPoolStateChip({ pool }: IProps) {
  const progress = (() => {
    switch (pool.state) {
      case InvestPoolState.Open:
        return ((now() - pool.openAt) * 100) / (pool.closeAt - pool.openAt);

      case InvestPoolState.Closed:
        return ((now() - pool.closeAt) * 100) / (pool.settleAt - pool.closeAt);
    }
  })();

  return progress == null ? (
    <Chip
      label={InvestPoolStateText[pool.state]}
      color={getInvestPoolStateColor(pool.state)}
      size="small"
      variant="filled"
    />
  ) : (
    <ChipWithProgress
      label={InvestPoolStateText[pool.state]}
      color={getInvestPoolStateColor(pool.state)}
      progress={progress}
    />
  );
}
