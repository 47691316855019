import { createSlice } from "@reduxjs/toolkit";
import { now } from "moment";
import { AppPage } from "../../types/app-page.type";
import { AppRefreshStatus } from "../../types/app-refresh-status.type";
import { addCommonReducers } from "../../utils/store-utils";

export const APP_STATE_NAME = 'app';

export interface AppState {
    refresh: {
        status: Record<AppPage, AppRefreshStatus>
        selectedPage: AppPage | null
        updatedAt: Record<AppPage, number>
    }
}

export const appState = createSlice({
    name: APP_STATE_NAME,
    initialState: {
        refresh: {
            status: {
                Dashboard: AppRefreshStatus.Idle,
                Companies: AppRefreshStatus.Idle,
                Payments: AppRefreshStatus.Idle,
                Offers: AppRefreshStatus.Idle,
                Requests: AppRefreshStatus.Idle,
                Plans: AppRefreshStatus.Idle,
                Schedules: AppRefreshStatus.Idle,
                Settings: AppRefreshStatus.Hide,
                UnderConstruction: AppRefreshStatus.Hide
            },
            selectedPage: null,
            updatedAt: {}
        }
    } as AppState,
    reducers: {
        changeRefresh(state, { payload }: { payload: { page?: AppPage, status?: AppRefreshStatus } }) {
            const page = payload.page ?? state.refresh.selectedPage;
            if (page) {
                state.refresh.status[page] = payload.status ?? state.refresh.status[page];
                state.refresh.selectedPage = page;
                if (payload.status === AppRefreshStatus.Idle) {
                    state.refresh.updatedAt[page] = now();
                }
            }
        },
    }, extraReducers: (builder) => {
        addCommonReducers(builder, appState.name);
    }
});

export const appActions = appState.actions;
export const appReducer = appState.reducer;

