import { createAsyncThunk } from "@reduxjs/toolkit";
import { investApi } from "../../http";
import { AppPage, AppRefreshStatus } from "../../types";
import { appActions } from "../app/app.state";
import { RootState } from "../store";
import { InvestState } from "./invest.state";

export const getInvestPoolsAction = createAsyncThunk(
    'invest/getInvestPools',
    async ({ page, pageSize }: { page?: number, pageSize?: number }, thunk) => {
        try {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.InProgress,
                page: AppPage.InvestPools
            }));

            const investState: InvestState = (<RootState>thunk.getState()).invest;
            page = page ?? investState.pagination.page;
            pageSize = pageSize ?? investState.pagination.pageSize;

            const paginatedInvestPools = await investApi.getInvestPools({
                from: page * pageSize,
                take: pageSize,
            });

            return { paginatedInvestPools, page, pageSize };
        } finally {
            thunk.dispatch(appActions.changeRefresh({
                status: AppRefreshStatus.Idle,
                page: AppPage.InvestPools
            }));
        }
    }
)